export const {
    // Vite
    MODE,
    BASE_URL,
    PROD,
    DEV,
    SSR,

    // Custom
    VITE_API_URL,
    VITE_SKIP_LOGIN,
    VITE_CREDIT_SERVER_URL,
    VITE_AUTH_SERVER_URL,
    VITE_BO_AUTH_SERVER_URL,
    VITE_PURCHASE_JOURNEY_URL,
    VITE_GOOGLE_ANALYTICS,
    VITE_ENVIRONMENT,
    VITE_CDN_APP_URL,
    VITE_CDN_APP_DIR,
    VITE_LATEST_VERSION_JSON,
    VITE_GOOGLE_TAG_MANAGER,
    VITE_GOOGLE_TAG_MANAGER_AUTH,
    VITE_AMPLITUDE_LOG_LEVEL,
    VITE_CDN_URL,
    VITE_CDN_ENCODER_API_URL,
    VITE_MANDATORY_UPDATE_VERSION,
    VITE_DEBUG_FETCH,
    VITE_TOKEN_LOGIN_VERSION,
} = import.meta.env

export default {
    MODE,
    BASE_URL,
    PROD,
    DEV,
    SSR,
    VITE_API_URL,
    VITE_SKIP_LOGIN,
    VITE_CREDIT_SERVER_URL,
    VITE_AUTH_SERVER_URL,
    VITE_BO_AUTH_SERVER_URL,
    VITE_PURCHASE_JOURNEY_URL,
    VITE_ENVIRONMENT,
    VITE_CDN_APP_URL,
    VITE_CDN_APP_DIR,
    VITE_LATEST_VERSION_JSON,
    VITE_GOOGLE_ANALYTICS,
    VITE_GOOGLE_TAG_MANAGER,
    VITE_GOOGLE_TAG_MANAGER_AUTH,
    VITE_AMPLITUDE_LOG_LEVEL,
    VITE_CDN_URL,
    VITE_CDN_ENCODER_API_URL,
    VITE_MANDATORY_UPDATE_VERSION,
    VITE_DEBUG_FETCH,
    VITE_TOKEN_LOGIN_VERSION,
}
