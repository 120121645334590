import { getStore } from '@store/RootStore'
import { checkInternetConnectivity } from '@utils/checkConnectivity'

// Interop bindings between UI and Webview

export async function sendUIReadyMessage() {
    if (typeof (globalThis as any).__postNativeMessage__ === 'function') {
        ;(globalThis as any).__postNativeMessage__('ready')
    }
}

export async function navigate(url: string) {
    if (typeof (globalThis as any).__postNativeMessage__ === 'function') {
        // eslint-disable-next-line no-console
        console.log('navigate', url)
        await (globalThis as any).__postNativeMessage__('navigateURL', url)
    }
}

export async function update() {
    if (typeof (globalThis as any).__postNativeMessage__ !== 'function') return

    await (globalThis as any).__postNativeMessage__('update')
}

export async function selectSoundBanksDir() {
    if (typeof (globalThis as any).__postNativeMessage__ !== 'function') return
    return (globalThis as any).__postNativeMessage__('selectSoundBanksDir')
}

export async function resetSoundBanksDir() {
    if (typeof (globalThis as any).__postNativeMessage__ !== 'function') return
    return (globalThis as any).__postNativeMessage__('resetSoundBanksDir')
}

export async function getSoundBanksDir() {
    if (typeof (globalThis as any).__postNativeMessage__ !== 'function') return

    return (globalThis as any).__postNativeMessage__('getSoundBanksDir')
}

export async function isSoundBanksDirDefault() {
    if (typeof (globalThis as any).__postNativeMessage__ !== 'function') return

    return (globalThis as any).__postNativeMessage__('isSoundBanksDirDefault')
}

export async function getMachineId() {
    if (typeof (globalThis as any).__postNativeMessage__ !== 'function') return

    return (globalThis as any).__postNativeMessage__('getMachineId')
}

export async function getMachineName() {
    if (typeof (globalThis as any).__postNativeMessage__ !== 'function') return

    return (globalThis as any).__postNativeMessage__('getMachineName')
}

export async function getInstalled() {
    if (typeof (globalThis as any).__postNativeMessage__ !== 'function') return

    return (globalThis as any).__postNativeMessage__('getInstalled')
}

export async function install(packageId: string, packageUrl: string, assetsAvailable: boolean = false) {
    if (typeof (globalThis as any).__postNativeMessage__ !== 'function') return

    const appStore = getStore()
    const { setConnectionStatus } = appStore

    checkInternetConnectivity(setConnectionStatus)

    return (globalThis as any).__postNativeMessage__('install', packageId, packageUrl, assetsAvailable)
}

export async function installUpdate(packageId: string, packageUrl: string) {
    if (typeof (globalThis as any).__postNativeMessage__ !== 'function') return

    return (globalThis as any).__postNativeMessage__('install', packageId, packageUrl)
}

export async function cancelInstall(packageId: string) {
    if (typeof (globalThis as any).__postNativeMessage__ !== 'function') return

    return (globalThis as any).__postNativeMessage__('cancelInstall', packageId)
}

export async function verifyInstall(packageId: string) {
    if (typeof (globalThis as any).__postNativeMessage__ !== 'function') return

    return (globalThis as any).__postNativeMessage__('verifyInstall', packageId)
}

export async function uninstall(packageId: string) {
    if (typeof (globalThis as any).__postNativeMessage__ !== 'function') return

    return (globalThis as any).__postNativeMessage__('uninstall', packageId)
}

export async function openLoginWindow(authUrl: string) {
    if (typeof (globalThis as any).__postNativeMessage__ !== 'function') return

    return (globalThis as any).__postNativeMessage__('login', authUrl)
}

export async function getAppVersion() {
    if (typeof (globalThis as any).__postNativeMessage__ !== 'function') return

    return (globalThis as any).__postNativeMessage__('getAppVersion')
}

export async function updateJwsToken(token: string) {
    if (typeof (globalThis as any).__postNativeMessage__ !== 'function') return

    return (globalThis as any).__postNativeMessage__('updateJwsToken', token)
}

export async function logoutFromBackOffice(logoutUrl: string) {
    if (typeof (globalThis as any).__postNativeMessage__ !== 'function') return

    return (globalThis as any).__postNativeMessage__('logout', logoutUrl)
}

/**
 * Let the app backend open a new window with a webview, to display the given url
 *
 * @param url  Url to open
 * @param title  Title of the window / Empty string opens the link in the default browser
 * @returns
 */
export async function openExternalUrl(url: string, title: string) {
    if (typeof (globalThis as any).__postNativeMessage__ !== 'function') return

    return (globalThis as any).__postNativeMessage__('openExternalUrl', url, title)
}

export async function openFullDiskAccess() {
    if (typeof (globalThis as any).__postNativeMessage__ !== 'function') return
    ;(globalThis as any).__postNativeMessage__('openFullDiskAccess')
}

export async function closeApp() {
    if (typeof (globalThis as any).__postNativeMessage__ !== 'function') return
    ;(globalThis as any).__postNativeMessage__('closeApp')
}

export async function loginWithToken() {
    if (typeof (globalThis as any).__postNativeMessage__ !== 'function') return
    await (globalThis as any).__postNativeMessage__('tokenLogin')
}
