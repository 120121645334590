import { detach, flow, getRoot, Instance, types } from 'mobx-state-tree'
import TagManager from 'react-gtm-module'
import md5 from 'md5'

import { IUser, SubscriptionState, User } from '@store/models/User'
import { loginWithToken, logoutFromBackOffice, openLoginWindow } from '@src/interop'
import ENV from '@constants/env'
import { formatGTMEvent, GTMEvent } from '@utils/gtm-event'
import ApiInstance from '@utils/api'
import { getCookie } from '@utils/cookie'
import { ALLOWED_COOKIE_DOMAINS, SESSION_COOKIE_TTL } from '@constants/cookies'
import { IRootStore } from '@store/RootStore'
import { IResponseAPI } from '@src/classes/ApiClass'
import { isVersionOutdated } from '@utils/misc'

export const AUTH_SERVER_URL = ENV.VITE_AUTH_SERVER_URL

export enum LoginState {
    loggedOut = 'loggedOut',
    loggingIn = 'loggingIn',
    loggedIn = 'loggedIn',
    loggingOut = 'loggingOut',
    checking = 'checking',
    errored = 'errored',
}

const AuthStore = types
    .model('Auth', {
        user: types.maybe(User),
        state: types.optional(types.enumeration(Object.keys(LoginState)), LoginState.loggedOut),
        loggingInWithToken: false,
    })
    .views(self => {
        return {
            get root(): IRootStore {
                return getRoot(self)
            },
            get loggedIn(): Boolean {
                return self.state === LoginState.loggedIn
            },
        }
    })
    .actions(self => {
        return {
            setLoggingInWithToken(value: boolean) {
                if (value) self.state = LoginState.loggingIn
                self.loggingInWithToken = value
            },
        }
    })
    .actions(self => {
        return {
            login() {
                if (
                    self.user ||
                    ![LoginState.loggedOut, LoginState.checking, LoginState.loggingIn, LoginState.errored].includes(self.state as LoginState)
                ) {
                    return
                }

                const fallbackToLoginWindow = isVersionOutdated(self.root.version ?? '', ENV.VITE_TOKEN_LOGIN_VERSION)

                if (fallbackToLoginWindow) {
                    openLoginWindow(AUTH_SERVER_URL + '/auth/image-line?plugins=true')
                    this.setState(LoginState.loggingIn)
                    return
                }

                this.setState(LoginState.loggingIn)
                self.setLoggingInWithToken(true)
                loginWithToken()
            },

            setUser(user: IUser) {
                if (self.user) {
                    detach(self.user)
                }

                self.user = user
            },
            setState(state: string) {
                self.state = state
            },
            setAsLoggedOut() {
                detach(self.user)
                this.setState(LoginState.loggedOut)
            },
        }
    })
    .actions(self => {
        return {
            check: flow(function* (showCheckingState = true): Generator<Promise<IResponseAPI>, boolean, IResponseAPI> {
                showCheckingState && self.setState(LoginState.checking)

                if (ENV.VITE_SKIP_LOGIN === 'true' && !window?.location?.hostname?.includes('cloud.image-line.com/')) {
                    // eslint-disable-next-line no-console
                    console.log('Skipping log-in...')
                    self.setUser(User.create({ id: 123, name: 'test-user@image-line.com', tierType: null, state: SubscriptionState.FREE }))
                    self.setState(LoginState.loggedIn)
                    return true
                }

                try {
                    /**
                     * We do this because the Azure temporal domains don't get the ttl cookie, so for these cases
                     * we need to avoid checking the cookie before sending requests to the credit server.
                     * Otherwise, the request won't get through.
                     */
                    const isAzureStagingDomain = !ALLOWED_COOKIE_DOMAINS.some(domain => {
                        return location.hostname.includes(domain)
                    })

                    const res = yield ApiInstance.session.getAccountInfo(isAzureStagingDomain)

                    if (!res.success) {
                        // eslint-disable-next-line no-console
                        console.log('isAzureStagingDomain', isAzureStagingDomain, location.hostname)
                        // eslint-disable-next-line no-console
                        console.log('Request url: ', ENV.VITE_CREDIT_SERVER_URL + '/account/info')

                        console.error('Error checking login status', res.statusText)
                        return false
                    }

                    const data = res.data.user

                    self.setUser(User.create({ id: data.id, name: data.user.username, state: data.user.state, tierType: data.user.tierType }))
                    self.setState(LoginState.loggedIn)

                    if (ENV.VITE_GOOGLE_TAG_MANAGER && self.root.gtmInitialized) {
                        const gtmUserIDArgs = formatGTMEvent(GTMEvent.SET_USER_ID, {
                            amplitudeUserID: md5(data.user.username),
                        })
                        TagManager.dataLayer({ dataLayer: gtmUserIDArgs.dataLayer })

                        const gtmLoginArgs = formatGTMEvent(GTMEvent.SIGNED_IN, {})
                        TagManager.dataLayer({ dataLayer: gtmLoginArgs.dataLayer })
                    }

                    const cookie = getCookie(SESSION_COOKIE_TTL)

                    if (cookie) {
                        const autoRefreshIn = parseInt(cookie) * 1000 - new Date().getTime()

                        // eslint-disable-next-line no-console
                        console.log('Auto-refreshing session in', autoRefreshIn / 60 / 1000, 'minutes')

                        setTimeout(async () => {
                            // @ts-ignore - this is a recursive call
                            await (this as IAuth).check(false)
                        }, autoRefreshIn)
                    }

                    return true
                } catch (e) {
                    console.error('Error checking login status', e)
                    self.setState(LoginState.errored)
                    return false
                }
            }),
            refreshToken: flow(function* (): Generator<Promise<IResponseAPI>, boolean, IResponseAPI> {
                const res = yield ApiInstance.session.revalidateSession()

                if (!res.success) {
                    // open login window
                    openLoginWindow(AUTH_SERVER_URL + '/auth/image-line?plugins=true')
                    return false
                }

                return true
            }),
            updateAccountInfo: flow(function* (): Generator<Promise<IResponseAPI>, boolean, IResponseAPI> {
                const res = yield ApiInstance.session.getAccountInfo()

                if (!res.success) {
                    console.error('Error updating user info', res.statusText)
                    return false
                }

                const data = res.data.user

                self.setUser(User.create({ id: data.id, name: data.user.username, state: data.user.state, tierType: data.user.tierType }))
                return true
            }),
            logout: flow(function* (): Generator<Promise<boolean | undefined | IResponseAPI>, void | false, any> {
                if (self.state !== LoginState.loggedIn) return

                const initialState = self.state
                self.setState(LoginState.loggingOut)

                if (ENV.VITE_GOOGLE_TAG_MANAGER && self.root.gtmInitialized) {
                    // Clear the GTM User ID
                    const gtmUserIDArgs = formatGTMEvent(GTMEvent.SET_USER_ID, {
                        amplitudeUserID: null,
                    })
                    TagManager.dataLayer({ dataLayer: gtmUserIDArgs.dataLayer })
                    self.root.setGTMInitialized(false)
                }

                const res: IResponseAPI = yield ApiInstance.session.logout()

                if (!res.success) {
                    self.setState(initialState)
                    console.error('error logging out', res.message)

                    return false
                }

                yield logoutFromBackOffice(ENV.VITE_BO_AUTH_SERVER_URL + '/logout?isAjax=true')

                detach(self.user)
                self.setState(LoginState.loggingOut)
            }),
        }
    })

export interface IAuth extends Instance<typeof AuthStore> {}

export default AuthStore
