import { ModalType, NotificationType } from '@constants/types'
import { getStore } from '@store/RootStore'
import { InstallMode, InstallState } from '@store/models/FLPlugin'
import { calculateAverageSpeed, getPlatform, waitFor } from '@utils/misc'
import { formatGTMEvent, GTMEvent, IDownloadEvent, IPluginPackageEvent, ISoundbankDirectoryEvent } from '@utils/gtm-event'
import TagManager from 'react-gtm-module'

import ENV from '@constants/env'
import { LoginState } from '@store/AuthStore'

import { handleTokenLogin } from './engine-helper'

export type IEngineResponse =
    | {
          result: string
          error: number
          message: string
          packageId: string
      }
    | {}

export const PackageManagementError: Record<number, string> = {
    0: 'Invalid Token',
    1: 'Invalid Server Response',
    2: 'Network Error',
    3: 'Invalid Package Archive',
    4: 'Invalid Package Meta',
    5: 'Invalid Installation',
    6: 'Package Not Installed',
    7: 'Package Already Installing',
    8: 'Package Already Uninstalling',
    9: 'Install Helper Error',
    10: 'Filesystem Error',
    11: 'Database Error',
    12: 'Auth Response Not Found',
    13: 'Canceled',
    14: 'Unknown Error',
}

const LoggedErrors = [
    0, // Invalid Token
    1, // Invalid Server Response
    2, // Network Error
    3, // Invalid Package Archive
    4, // Invalid Package Meta
    5, // Invalid Installation
    9, // Install Helper Error
    10, // Filesystem Error
    11, // Database Error
    12, // Auth Response Not Found
    14, // Unknown Error
]

export enum EngineTaskType {
    INSTALL = 'install',
    VERIFY_INSTALL = 'verifyInstall',
    UNINSTALL = 'uninstall',
    DOWNLOAD_PROGRESS = 'downloadProgress',
    UNPACK_PROGRESS = 'unpackProgress',
    INSTALL_PROGRESS = 'installProgress',
    BO_AUTH = 'BOAuth',
    CLOSE_APP_REQUEST = 'closeAppRequested',
    SOUNDS_BANKS_DIR_SELECTED = 'soundBanksDirSelected',
    TOKEN_LOGIN = 'tokenLogin',
}

export enum InstallResult {
    SUCCESS = 'success',
    ERROR = 'error',
}

export enum AuthResult {
    LOGUT_SUCCESS = 'logout_success',
    ERROR = 'error',
    CANCELLED = 'cancelled',
}

export const APP_UPDATE = 'app-update'

export const responseHandler = async (task: string, data: IEngineResponse = {}) => {
    // eslint-disable-next-line no-console
    console.log('responseHandler', task, data)
    const { result, message, packageId, error } = (data as any) || {}
    const appStore = getStore()
    const { feed, modal, gtmInitialized, setInstallerDownloadedSize, setIsAppUpdating, setAppError, setSoundBanksDirectory } = appStore
    const plugin = packageId !== '' ? feed.plugins.get(packageId) : null

    // eslint-disable-next-line no-console
    console.log('App version: ', appStore.version)

    if (result === InstallResult.ERROR && LoggedErrors.includes(error)) {
        // log errors to Sentry
        /* eslint-disable no-console */
        console.log('App version:', appStore.version)
        console.log('Package id:', packageId)
        console.log('Message:', message)
        console.error(`Engine Error: ${task} - ${PackageManagementError[error]}`)
        /* eslint-enable no-console */
    }

    switch (task) {
        case EngineTaskType.INSTALL:
            {
                if (packageId === APP_UPDATE) {
                    setInstallerDownloadedSize(0)
                    setIsAppUpdating(false)

                    if (result === InstallResult.ERROR) {
                        // There was an error
                        if (error !== 13) {
                            console.error(`An error was encountered updating the app: ${PackageManagementError[error]}`)
                            setAppError(PackageManagementError[error])
                            return
                        }
                    }

                    return
                }

                if (!plugin) return

                const allCurrentlyInstallingDependencies = feed.pluginsList.filter(p => {
                    return p.isInstalling
                }) as any[]

                if (result === InstallResult.SUCCESS) {
                    if (
                        plugin.installStatus === InstallState.INSTALLING_AS_DEPENDENCY ||
                        plugin.installStatus === InstallState.FINALIZING_INSTALL_AS_DEPENDENCY
                    ) {
                        plugin.completeInstall()

                        // remove this dependency from all plugins that are currently installing
                        for (const p of allCurrentlyInstallingDependencies) {
                            if (p.dependencyQueue.includes(plugin.appId)) {
                                // remove, if this was installed as dependency
                                p.setDependencyQueue(
                                    p.dependencyQueue.filter((id: string) => {
                                        return id !== plugin.appId
                                    }),
                                )
                                // call callback on plugin, which was waiting for this dependency
                                p.installedSuccessfullyCallback()
                            }
                        }

                        // now check all dependencies, to catch if something was fixed
                        appStore.feed.getInstalledPlugins()
                    } else {
                        // this was the main plugin, everything fine
                        plugin.completeInstall()

                        plugin.setInstalledVersion(plugin.platformPackage?.version ?? null)
                        appStore.notifications.addNotification(NotificationType.SUCCESS, `${plugin.name} Installed`)

                        // check all dependencies, to catch if something was fixed
                        appStore.feed.getInstalledPlugins()

                        if (ENV.VITE_GOOGLE_TAG_MANAGER && gtmInitialized) {
                            const gtmInstallCompletedArgs = formatGTMEvent(GTMEvent.PLUGIN_INSTALL_COMPLETED, {
                                pluginAppId: plugin.appId,
                                pluginName: plugin.name,
                                pluginVersion: plugin.platformPackage?.version,
                                pluginSize: plugin.platformPackage?.size,
                                platform: getPlatform(),
                                downloadSpeed: plugin.averageDownloadSpeed ? plugin.averageDownloadSpeed.toFixed(2) : 0,
                            } as IDownloadEvent & IPluginPackageEvent)
                            TagManager.dataLayer({ dataLayer: gtmInstallCompletedArgs.dataLayer })
                        }
                    }
                } else {
                    // something went wrong
                    if (error !== 13) {
                        plugin.setStatus(InstallState.ERRORED_ON_INSTALL)
                        plugin.setError(`${PackageManagementError[error]} : ${message}`)
                        appStore.notifications.addNotification(NotificationType.ERROR, `${plugin.name} failed to install`)
                    } else {
                        // error === 13 is download canceled
                        plugin.completeUninstall()
                        appStore.feed.getInstalledPlugins()
                    }

                    if (allCurrentlyInstallingDependencies.length === 0) return

                    // get the plugin(s), which was/were installing this as dependency
                    // and set correct status, error message
                    const plugins = allCurrentlyInstallingDependencies.filter(p => {
                        return p.dependencyQueue.includes(plugin.appId)
                    })

                    for (const p of plugins) {
                        p.setStatus(InstallState.BROKEN)
                        p.setError(`Dependency '${plugin.name}' failed to install`)
                    }
                }
            }
            break

        case EngineTaskType.VERIFY_INSTALL:
            if (!plugin) return

            if (result === InstallResult.SUCCESS) {
                plugin.setStatus(InstallState.INSTALLED)
                plugin.checkDependencies()

                // Reset the fixing install flag, as it may have been set
                plugin.setIsFixingInstall(false)
                feed.dequeueVerifyingPlugin(plugin.appId)

                return
            }

            // Verification failed and is fixing, so go ahead with the install
            if (plugin.isFixingInstall) {
                plugin.installPlugin(InstallMode.FULL_INSTALL)
                plugin.setIsFixingInstall(false)
                feed.dequeueVerifyingPlugin(plugin.appId)

                return
            }

            plugin.setStatus(InstallState.BROKEN)
            plugin.setError(`${PackageManagementError[error]} : ${message}`)
            feed.dequeueVerifyingPlugin(plugin.appId)
            break

        case EngineTaskType.UNINSTALL:
            if (!plugin) return

            if (result === InstallResult.SUCCESS) {
                plugin.completeUninstall()

                if (!plugin.isHiddenFromCatalog) {
                    appStore.notifications.addNotification(NotificationType.SUCCESS, `${plugin.name} Removed`)
                }

                // verify installation for all installed plugins
                await feed.getInstalledPlugins()

                if (plugin.isHiddenFromCatalog) return

                /**
                 * This functionality is disabled for now to avoid
                 * removing packages that are still needed by other plugins
                 */

                // // remove orphaned packages
                // const appIdsToCheck = plugin.platformPackage?.dependencies?.map((d: IFLPluginDependency) => {
                //     return d.appId
                // })

                // feed.removeOrphanedPackages(appIdsToCheck)
                return
            }

            plugin.setStatus(InstallState.ERRORED_ON_UNINSTALL)
            plugin.setError(`${PackageManagementError[error]} : ${message}`)
            appStore.notifications.addNotification(NotificationType.ERROR, `${plugin.name} failed to uninstall`)
            break

        case EngineTaskType.DOWNLOAD_PROGRESS:
            if (packageId === APP_UPDATE) {
                setInstallerDownloadedSize(parseInt(message))
                break
            }

            if (!plugin) return

            plugin.setDownloadSize(parseInt(message))

            // Download completed and GTM is Initialized
            if (plugin.downloadSize === plugin.platformPackage?.size && plugin.downloadStartedAt && ENV.VITE_GOOGLE_TAG_MANAGER && gtmInitialized) {
                // Calculate and set the average download speed for this plugin
                const averageDownloadSpeed = calculateAverageSpeed(plugin.platformPackage?.size, plugin.downloadStartedAt, new Date())
                plugin.setAverageDownloadSpeed(averageDownloadSpeed)
                plugin.setDownloadStartedAt(null)
            }

            break

        case EngineTaskType.UNPACK_PROGRESS:
            if (!plugin) return

            plugin.setUnpackProgress(parseInt(message))
            break

        case EngineTaskType.INSTALL_PROGRESS:
            if (!plugin) return

            plugin.setInstallProgress(parseInt(message))
            break

        case EngineTaskType.BO_AUTH:
            switch (result) {
                case AuthResult.LOGUT_SUCCESS:
                    appStore.auth.setState(LoginState.loggedOut)

                    waitFor(1000).then(() => {
                        location.reload()
                    })
                    break
                case AuthResult.CANCELLED:
                case AuthResult.ERROR:
                    appStore.auth.setState(LoginState.errored)
                    break
            }

            break

        case EngineTaskType.CLOSE_APP_REQUEST:
            modal.showModal(ModalType.CONFIRM_CLOSE_APP, {})
            break

        case EngineTaskType.SOUNDS_BANKS_DIR_SELECTED:
            // Unblock the UI
            modal.closeModal()

            if (!data) return

            setSoundBanksDirectory(data as string)

            if (ENV.VITE_GOOGLE_TAG_MANAGER && gtmInitialized) {
                const gtmSoundbankDirectoryArgs = formatGTMEvent(GTMEvent.SOUNDBANKS_DIRECTORY_CHANGED, {
                    path: data,
                    platform: getPlatform(),
                } as Partial<ISoundbankDirectoryEvent>)
                TagManager.dataLayer({ dataLayer: gtmSoundbankDirectoryArgs.dataLayer })
            }

            break
        case EngineTaskType.TOKEN_LOGIN:
            if (await handleTokenLogin(data, appStore.auth)) {
                location.reload()
            }

            appStore.auth.setLoggingInWithToken(false)
            appStore.auth.setState(LoginState.errored)
            break
    }
}
