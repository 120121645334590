import { IAuth, LoginState } from '@store/AuthStore'
import ApiInstance from './api'

export const handleTokenLogin = async (data: any, auth: IAuth) => {
    if (data?.error && data.error !== '') {
        auth.setState(LoginState.errored)
        return false
    }

    if (!data?.message) {
        auth.setState(LoginState.errored)
        return false
    }

    const response = await ApiInstance.session.loginWithToken(data?.message)

    if (!response.success) {
        console.error('Error logging in with token', response.statusText)
        return false
    }

    return true
}
